export default {
  computed: {
    search: {
      get() {
        return this.$route.query.search;
      },
      set(value) {
        this.lodash.appendRouteQuery(this.$router, this.$route, {
          search: value,
        });
      },
    },
  },
  watch: {
    search: {
      immediate: true,
      handler(search) {
        this.triggerSearch(search);
      },
    },
  },
  methods: {
    triggerSearch(value) {
      console.log("search => ", value)
    }
  }
}

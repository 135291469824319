<template>
  <CRow class="mb-5">
    <CCol lg="3">
      <TAside>
        <CListGroup>
          <CListGroupItem>
            <TInputSimpleSearch placeholder="Search sku" :value.sync="search" />
          </CListGroupItem>
          <CListGroupItem
            v-for="(box, index) in boxes_available"
            :key="`${index}${box.id}`"
            :color="box_id_selected == box.id ? 'primary' : 'secondary'"
            :to="_.getAppendRouteQuery($route, { box_id: box.id })"
          >
            <SCardBox :box="box" />
          </CListGroupItem>
          <CListGroupItem v-if="!boxes_available.length">
            <TMessageNotFound />
          </CListGroupItem>
          <CListGroupItem v-if="boxes_available_loading">
            <TSpinner :loading="boxes_available_loading" />
          </CListGroupItem>
          <SButtonLoadmore store="warehouse.boxes_decouple_items" />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="9">
      <CRow v-if="box_id_selected">
        <CCol col="12">
          <CCard>
            <CCardHeader>
              <div class="d-flex align-items-center">
                <TMessage content="Decouple" bold>
                  <template #suffix> {{ box_id_selected || "..." }} </template>
                </TMessage>
              </div>
            </CCardHeader>
            <CCardBody>
              <div
                v-for="item in decouple_items"
                :key="item.product_id"
                class="mt-3"
              >
                <CRow style="w-100">
                  <CCol col="5">
                    <SCardProductItems
                      widthAuto
                      :item="item.product"
                      resource="/warehouse/management/products"
                    />
                  </CCol>
                  <CCol col="3">
                    <TInputText
                      label="Items in box"
                      :value.sync="item.quantity"
                      disabled
                    />
                  </CCol>
                  <CCol col="3">
                    <TInputNumber
                      label="Quantity to split"
                      :value.sync="item.decouple_quantity"
                      @change="updateDecoupleItem(item.product_id, $event)"
                    />
                  </CCol>
                </CRow>
              </div>
              <hr />
              <CRow class="mt-3">
                <CCol col="12">
                  <TInputCheckbox
                    :checked="isMoveItem"
                    @change="isMoveItem = !isMoveItem"
                    label="Move items to another box"
                    class="h5"
                  />
                </CCol>
              </CRow>
              <CRow class="my-2">
                <CCol col="6">
                  <TInputText
                    label="Decouple box"
                    :value.sync="box_id_selected"
                    class="mb-3 text-uppercase"
                    disabled
                    v-if="isMoveItem"
                  />
                  <TTableAsForm
                    :fields="boxSelectedField"
                    :data="boxCurrent"
                    :splitLeft="5"
                    title="Update decouple box"
                    style="font-size: 1rem"
                    :addRowClasses="['pb-1']"
                  >
                    <template #weight>
                      <TInputNumber
                        :maskOptions="{ integerLimit: 3 }"
                        :value.sync="boxCurrent.weight"
                        @update:value="boxCurrent.weight = $event"
                        append="kg"
                      />
                    </template>
                    <template #height>
                      <TInputNumber
                        :maskOptions="{ integerLimit: 3 }"
                        :value.sync="boxCurrent.height"
                        @update:value="boxCurrent.height = $event"
                        append="cm"
                      />
                    </template>
                    <template #length>
                      <TInputNumber
                        :maskOptions="{ integerLimit: 3 }"
                        :value.sync="boxCurrent.length"
                        @update:value="boxCurrent.length = $event"
                        append="cm"
                      />
                    </template>
                    <template #width>
                      <TInputNumber
                        :maskOptions="{ integerLimit: 3 }"
                        :value.sync="boxCurrent.width"
                        @update:value="boxCurrent.width = $event"
                        append="cm"
                      />
                    </template>
                  </TTableAsForm>
                </CCol>
                <CCol col="6">
                  <SSelectBox
                    v-if="isMoveItem"
                    label="The box is move to"
                    class="mb-3 text-uppercase"
                    :value.sync="box_decouple_id_selected"
                    @change="selectDecoupleBox"
                  />
                  <TTableAsForm
                    :fields="boxSelectedField"
                    :data="boxDecouple"
                    :splitLeft="5"
                    :title="
                      isMoveItem ? 'Update box moved to' : 'Update new box'
                    "
                    style="font-size: 1rem"
                    :addRowClasses="['pb-1']"
                  >
                    <template #weight>
                      <TInputNumber
                        :value.sync="boxDecouple.weight"
                        @update:value="boxDecouple.weight = $event"
                        append="kg"
                        :maskOptions="{ integerLimit: 3 }"
                      />
                    </template>
                    <template #height>
                      <TInputNumber
                        :maskOptions="{ integerLimit: 3 }"
                        :value.sync="boxDecouple.height"
                        @update:value="boxDecouple.height = $event"
                        append="cm"
                      />
                    </template>
                    <template #length>
                      <TInputNumber
                        :maskOptions="{ integerLimit: 3 }"
                        :value.sync="boxDecouple.length"
                        @update:value="boxDecouple.length = $event"
                        append="cm"
                      />
                    </template>
                    <template #width>
                      <TInputNumber
                        :maskOptions="{ integerLimit: 3 }"
                        :value.sync="boxDecouple.width"
                        @update:value="boxDecouple.width = $event"
                        append="cm"
                      />
                    </template>
                  </TTableAsForm>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter class="d-flex">
              <TButton
                color="success"
                :messageOptions="{ bold: true, noTranslate: true }"
                :options="{ disabled: loading }"
                icon="cil-data-transfer-down"
                class="mx-auto"
                @click="decouple"
              >
                <template #content>
                  <TMessage content="Split box items" bold class="ml-2" />
                </template>
              </TButton>
            </CCardFooter>
          </CCard>
        </CCol>
        <CCol col="12">
          <CCard v-if="box_id_selected && boxes_ref.length">
            <CCardHeader class="d-flex flex-wrap align-items-center">
              <div class="d-flex align-items-center">
                <TMessage content="SKUs separate from" bold>
                  <template #suffix>
                    <span class="ml-1">{{ box_id_selected || "..." }}</span>
                  </template>
                </TMessage>
                <TSpinner :loading="boxes_ref_loading" />
              </div>
            </CCardHeader>
            <CCardBody>
              <TMessageNoData content="No SKU" v-if="!boxes_ref.length" />
              <CRow v-else class="d-flex align-items-stretch">
                <SCardSku
                  v-for="box in boxes_ref"
                  :key="box.id"
                  :box="box"
                  class="my-3 mx-2"
                />
              </CRow>
              <SButtonLoadmore store="warehouse.boxes_ref_decouple" />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";
import searchable from "../../mixins/searchable";
export default {
  mixins: [searchable],
  data() {
    return {
      decouple_items: [],
      boxSelectedField: [
        { key: "length", label: "Length" },
        { key: "height", label: "Height" },
        { key: "width", label: "Width" },
        { key: "weight", label: "Weight" },
      ],
      boxCurrent: {},
      boxDecouple: {},
      box_decouple_id_selected: "",
      isMoveItem: false,
    };
  },
  watch: {
    box_id_selected: {
      immediate: true,
      handler(id) {
        if (id) {
          this.fetchBox();
          this.fetchBoxesRef();
          this.boxCurrent = {};
          this.boxDecouple = {};
          this.box_decouple_id_selected = "";
          this.isMoveItem = false;
        }
      },
    },
    isMoveItem() {
      this.boxDecouple = {};
      this.box_decouple_id_selected = "";
    },
  },
  computed: {
    ...mapGetters({
      boxes_available: "warehouse.boxes_decouple_items.list",
      boxes_available_loading: "warehouse.boxes_decouple_items.loading",
      boxes_ref: "warehouse.boxes_ref_decouple.list",
      boxes_ref_loading: "warehouse.boxes_ref_decouple.loading",
      loading: "warehouse.decouple_box_items.loading",
    }),
    box_id_selected() {
      return this.$route.query.box_id;
    },
    box_selected() {
      return this.$store.getters["warehouse.boxes_decouple_items.detail"];
    },
  },
  methods: {
    fetchBoxesRef() {
      this.$store.dispatch("warehouse.boxes_ref_decouple.apply-query", {
        sort: "-created_at",
        "filter[box_ref_id]": this.box_id_selected,
      });
    },
    fetchBox() {
      this.$store
        .dispatch(
          "warehouse.boxes_decouple_items.detail.fetch",
          this.box_id_selected
        )
        .then((data) => {
          this.decouple_items = data.items.map((x) => {
            return {
              ...x,
              decouple_quantity: 0,
            };
          });
          this.boxCurrent = {
            length: data.length,
            width: data.width,
            height: data.height,
          };
        });
    },
    selectDecoupleBox(box) {
      this.boxDecouple = {
        length: box.length,
        width: box.width,
        height: box.height,
      };
    },
    triggerSearch(value) {
      if (value || value == "") {
        this.$store.dispatch("warehouse.boxes_decouple_items.apply-query", {
          "filter[id]": value,
        });
      } else
        this.$store.dispatch(
          "warehouse.boxes_decouple_items.fetch.if-first-time"
        );
    },
    decouple() {
      let data = {};
      const boxSelected = {
        ...this.boxCurrent,
        id: this.box_id_selected,
      };
      const boxDecouple = {
        ...this.boxDecouple,
        id: this.box_decouple_id_selected,
      };
      const boxSelectedC = this.lodash.mapKeys(boxSelected, (value, key) => {
        return `box[${key}]`;
      });
      const boxDecoupledC = this.lodash.mapKeys(
        this.lodash.pickBy(boxDecouple),
        (value, key) => {
          return `box_decouple[${key}]`;
        }
      );
      data = { ...data, ...boxSelectedC, ...boxDecoupledC };
      this.decouple_items.forEach((object, index) => {
        const item = {
          product_id: object.product_id,
          quantity: object.decouple_quantity,
        };
        if (item.quantity > 0) {
          const boxItem = this.lodash.mapKeys(item, (value, key) => {
            return `items[${index}][${key}]`;
          });
          data = { ...data, ...boxItem };
        }
      });
      this.$store
        .dispatch(
          "warehouse.decouple_box_items.create",
          this.lodash.pickBy(data)
        )
        .then(() => {
          setTimeout(() => {
            this.triggerSearch();
            this.fetchBoxesRef();
            this.fetchBox();
          }, 1000);
        });
    },
  },
};
</script>
